<template>
  <TileFrame :size="size" @click="handleTileClick">
    <template #image>
      <ImageBasic
        :image-data="tileData.image"
        class="object-cover w-full h-full aspect-video"
        :class="{
          ['grayscale-[88%] ease-in duration-300']: !tileData.isAvailable,
        }"
      ></ImageBasic>
      <OfficeStatusIndicator
        v-if="tileData.typeHandle === TileTypeHandle.OFFICE"
        :office-label="tileData.office.label"
        :is-new="isNew(tileData.office.date)"
      />
    </template>

    <div
      class="grid h-full p-md"
      :class="{
        'grid-rows-[min-content_max-content]': !isSlim,
        'grid-rows-[min-content_max-content] content-between': isSlim,
      }"
    >
      <div class="vdv-tile-contents-office__top">
        <h5 ref="overline" class="items-start text-sm font-light">
          {{ t(tileData.overline) }}
        </h5>
        <div class="text-md pt-sm">
          <h4 class="break-words">
            {{ tileData.title }}
          </h4>
        </div>
      </div>

      <SlimContent
        v-if="isSlim"
        :tile-data="tileData"
        :query-args="queryArgs"
      />
      <WideContent v-else :tile-data="tileData" :query-args="queryArgs" />
    </div>
  </TileFrame>
</template>
<script lang="ts"></script>
<script setup lang="ts">
import type { LocationQueryRaw } from 'vue-router';
import { ETileSize } from '@/@types/tile-size';
import { getDaysTillNow } from '~~/src/helpers/dayJs';
import TileFrame from '../../tileFrame/TileFrame.vue';
import SlimContent from './components/SlimContent.vue';
import WideContent from './components/WideContent.vue';
import ImageBasic from '@/components/components/core/basicImage/image-basic.vue';
import type { OfficeTileData } from './tileType';
import { TileTypeHandle } from '../../../types';
import OfficeStatusIndicator from '@/components/office/office-status-indicator.vue';

const { t } = useTrans();

const overline = ref<HTMLDivElement>(null);

defineExpose({ overline });

const props = defineProps({
  tileData: {
    type: Object as PropType<OfficeTileData>,
    required: true,
  },
  size: {
    type: String as PropType<ETileSize>,
    required: true,
  },
  queryArgs: {
    type: Object as PropType<LocationQueryRaw>,
    required: false,
    default: null,
  },
});

const isSlim = computed(() => {
  return props.size == ETileSize.SLIM;
});

function handleTileClick() {
  navigateTo({
    path: '/' + props.tileData.uri,
    query: props.queryArgs,
  });
}

function isNew(date: Date) {
  return getDaysTillNow(date) <= 14;
}
</script>
<style scoped lang="postcss"></style>
