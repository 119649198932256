<template>
  <div
    :class="{
      'flex flex-row justify-between items-end': !isMobile,
    }"
  >
    <div class="flex items-center justify-between pt-sm">
      <div class="text-md">
        <div
          v-for="(item, index) in getOfficeTableRowItemsNEW(
            tileData.office,
            true,
          )"
          :key="`officeitem_${index}`"
          class="flex pb-2xs last:pb-0"
        >
          <div class="flex">
            <div class="w-[18px] shrink-0">
              <FaIcon :icon-class="item.icon" />
            </div>
            <div :class="`pl-sm pr-xs`">
              <!-- eslint-disable vue/no-v-html -->
              <span
                v-html="item.label.length !== 0 ? t(item.label) : item.value"
              ></span>
            </div>
          </div>

          <div v-if="item.label.length !== 0">
            {{ item.value }}
          </div>
        </div>
      </div>
    </div>

    <div class="relative flex flex-col self-end pt-md">
      <div class="flex flex-col text-right">
        <div class="text-3xl font-bold">
          {{
            tileData.office.price
              ? getFormattedPrice(tileData.office.price)
              : t('office.notSpecified')
          }}
        </div>
        <div class="text-sm mt-[-3pt]">
          {{ t(tileData.priceSubline) }}
        </div>
      </div>
      <LinkBasic
        :label="t('basicLink.showDetails')"
        :link="`/${tileData.uri}`"
        :filter-params="queryArgs"
        target="_self"
        icon="fas fa-chevron-right"
        class="justify-end text-vdv-base pt-md"
      />
    </div>
  </div>
</template>
<script lang="ts"></script>
<script setup lang="ts">
import LinkBasic from '@/components/components/core/basicLink/basic-link.vue';
import { mq_breakpointIsMobile } from '@/injectionSymbols';
import { getFormattedPrice } from '~~/src/utils/pageData/utils';
import type { LocationQueryRaw } from 'vue-router';
import { type OfficeTileData } from '../tileType';
import { getOfficeTableRowItemsNEW } from '~~/src/utils/pageData/getOfficeTableRowItems';
import FaIcon from '@/components/fa-icon.vue';

const { t } = useTrans();

const isMobile = inject(mq_breakpointIsMobile);

defineProps({
  tileData: {
    type: Object as PropType<OfficeTileData>,
    required: true,
  },
  queryArgs: {
    type: Object as PropType<LocationQueryRaw>,
    required: false,
    default: null,
  },
});
</script>
<style scoped lang="postcss"></style>
