<template>
  <div
    v-if="isNew || officeLabel !== EOfficeLabel.NONE"
    class="vdv-office-status"
  >
    <div
      v-if="officeLabel !== EOfficeLabel.NONE"
      class="vdv-office-status__main"
    >
      {{ getStatusFromEnum(officeLabel) }}
    </div>
    <div
      v-if="isNew && officeLabel !== EOfficeLabel.RESERVED"
      class="vdv-office-status--active"
    >
      <div class="vdv-office-status__main">
        {{ t('office.new') }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { EOfficeLabel } from '@/@types/office-label';

const { t } = useTrans();
defineProps({
  officeLabel: {
    type: String as PropType<EOfficeLabel>,
    required: true,
  },
  isNew: {
    type: Boolean,
    required: true,
  },
});

function getStatusFromEnum(icon: EOfficeLabel) {
  switch (icon) {
    case EOfficeLabel.RESERVED:
      return t('office.reserved');
    default:
      return '';
  }
}
</script>
<style scoped lang="postcss">
.vdv-office-status {
  @apply absolute top-md right-0 bg-vdv-btn-base text-white;
}

.vdv-office-status__main {
  @apply relative px-xs font-bold text-sm h-[24px] leading-[24px];
}
.vdv-office-status--active {
  @apply bg-sign-yellow;
}

.vdv-office-status__main::before {
  content: '\A';
  border-style: solid;
  border-width: 0 10px 24px 0;
  border-color: transparent #555555 transparent transparent;
  position: absolute;
  left: -10px;
}

.vdv-office-status--active > .vdv-office-status__main::before {
  border-color: transparent #deab53 transparent transparent;
}
</style>
